import Buyer from "../assets/BuylerImg.png";
import RealtimeChatApp from "../assets/RealtimeChatApp.png";
import MRB from "../assets/MRB.png";
import StartUpExp from "../assets/StartUpExp.png";

import { v4 as uuidv4 } from "uuid";

export const projects = [
  {
    id: uuidv4(),
    name: "Startup Expansion Dashboard",
    desc:"This project was about extracting some insights from the data of various startups. It shows the profit of different startups and the ups and downs of marketing spending as well. Then a Power BI dashboard was built to summarise the data.",
    img: StartUpExp,
    techs: ["Python", "Power BI"],
    cat: "Data Analysis",
    github: "https://github.com/Ridaelb/Startup-Expansion-Analysis",
    link: "https://app.powerbi.com/reportEmbed?reportId=08e89c99-2143-432c-8645-be6fabdce5bc&autoAuth=true&ctid=4f114661-7d76-4322-83ac-538bd4c8ea9b"
  },
  {
    id: uuidv4(),
    name: "Buyler",
    desc: "Buyler is a web application aims to connect travelers who have extra luggage space to people with shipping and shopping needs.",
    img: Buyer,
    techs: ["React.js", "Spring Boot", "MySQL"],
    cat: "Web development"
  },
  {
    id: uuidv4(),
    name: "Realtime Chat Application",
    desc: "This project is a website that allows real-time chatting between a group of users who can enter a room using the same room name.",
    img: RealtimeChatApp,
    techs: ["React.js", "Node.js", "Express.js", "Socket.io"],
    cat: "Web development",
    link: "https://rt-chat-application.netlify.app/",
    github: "https://github.com/Ridaelb/realtime-chat-app",
  },
  {
    id: uuidv4(),
    name: "Shampoo Label",
    desc: "This project was a product packaging design for a company selling natural products with a Moroccan touch in Chicago, USA.",
    img: MRB,
    techs: ["Adobe Photoshop"],
    cat: "Graphic Design",
    link: "https://moroccanbeautyrituals.net/"
  }
];
