import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import { motion } from "framer-motion";
import { Button, TextField } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";

import "./Contact.css";

function Contact() {
  const textFieldStyle = { marginBlock: "10px" };

  const form = useRef();

  const [name, setName] = useState();
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const [error, setError] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();
    console.log(isFormInvalid());
    if (isFormInvalid()) {
      emailjs
        .sendForm(
          "service_txyotvf",
          "template_skxyxkr",
          form.current,
          "YR317fzMUWX0S5LJn"
        )
        .then(
          (result) => {
            console.log(result.text);
          },
          (error) => {
            console.log(error.text);
          }
        );
      document.getElementById("contact-thanks").style.height = "100%";
      document.getElementById("contact-thanks").style.opacity = "1";
      document.getElementById("contact-thanks").style.zIndex = "2";
      document.getElementById("contact-form").classList.remove("error");
    } else {
      setError(true);
    }
  };

  const isFormInvalid = () => {
    return name && email.includes("@") && message.length >= 20;
  };

  return (
    <motion.section
      initial={{ opacity: 0, y: 200 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.6 }}
      viewport={{ once: true }}
      id="contact"
      className="contact-container"
    >
      <form
        id="contact-form"
        className="form-container"
        ref={form}
        onSubmit={sendEmail}
      >
        <div id="contact-thanks" className="contact-thanks">
          <h1>Thanks for reaching out!</h1>
        </div>
        <div id="contact-content" className="contact-form">
          <h1>Send me a message!</h1>
          <p className="contact-description">
            Got a question or proposal, or just want to say hello? Go ahead.
          </p>
          <TextField
            label="Full Name"
            style={textFieldStyle}
            variant="filled"
            name="user_name"
            error={!name && error}
            helperText={!name && error ? "Please enter your name" : ""}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
          <TextField
            label="Email"
            name="user_email"
            style={textFieldStyle}
            variant="filled"
            error={!email.includes("@") && error}
            helperText={
              !email.includes("@") && error ? "This an invalid email" : ""
            }
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
          <TextField
            label="Message"
            style={textFieldStyle}
            variant="filled"
            multiline
            rows={4}
            name="message"
            error={!email.includes("@") && !email.includes(".") && error}
            helperText={
              message.length < 20 && error
                ? "Message must contain 20 character at least. You are currently using " +
                  message.length
                : ""
            }
            onChange={(e) => {
              setMessage(e.target.value);
            }}
          />
          <Button type="submit" variant="outlined" endIcon={<SendIcon />}>
            Shoooot
          </Button>
        </div>
      </form>
    </motion.section>
  );
}

export default Contact;
