import React, { useState } from "react";
import ReactGA from "react-ga";
import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import { motion } from "framer-motion";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { Pagination, Navigation } from "swiper";

import { projects } from "../../constants/index";
import ProjectItem from "./ProjectItem";
import { Autocomplete, TextField } from "@mui/material";

const ProjectsStyle = styled.section`
  height: 100vh;
  align-items: center;
  .work-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 600px) {
      display: block;
    }
  }
  .project-filter {
    width: 20vw;
    @media (max-width: 600px) {
      width: 100%;
      margin: 20px 0;
    }
  }
  .swiper {
    margin-top: 20px;
    border-radius: 12px;
    transition: all 0.3s;
  }

  .swiper:hover {
    transform: scale(1.03);
  }

  .swiper-slide {
    opacity: 0.2;
    transition: all 0.5s ease-in-out;
  }

  .swiper-slide-visible {
    opacity: 1;
  }
  .swiper-button-prev,
  .swiper-button-next {
    position: absolute;
    height: 50px;
    width: 50px;
    background: var(--blury-background);
    z-index: 10;
    right: 70px;
    left: auto;
    top: 6px;
    transform: translateY(50%);
    color: var(--text-primary);
    border-radius: 8px;
    @media (max-width: 800px) {
    }
  }

  .swiper-button-next {
    right: 10px;
  }

  .swiper-button-prev::after,
  .swiper-button-next::after {
    font-size: 1.3rem;
  }

  .swiper-pagination {
  }
  .swiper-pagination-bullet {
    background-color: var(--text-sec);
  }
  .swiper-pagination-bullet-active {
    background: var(--text-primary);
  }
`;

function Projects() {
  const cats = projects
    .map((item) => item.cat)
    .filter((value, index, self) => self.indexOf(value) === index);

  const [filterBy, setFilterBy] = useState();

  return (
    <ProjectsStyle id="work">
      <motion.div
        initial={{ opacity: 0, y: 200 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
        viewport={{ once: true }}
        className="work-header"
      >
        <h1>Some Things I’ve Built</h1>
        <Autocomplete
          className="project-filter"
          options={cats}
          getOptionLabel={(option) => (option ? option : "")}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="filled"
              label="Fields"
              placeholder="Star Typing"
            />
          )}
          onChange={(event, value) => {
            setFilterBy(value);
            ReactGA.event({ category: "FilterWith", action: value });
          }}
        />
      </motion.div>
      <motion.div
        initial={{ opacity: 0, y: 200 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
        viewport={{ once: true }}
      >
        <Swiper
          speed={1000}
          autoHeight={true}
          pagination={{
            clickable: true,
          }}
          navigation={true}
          modules={[Pagination, Navigation]}
          slidesPerView={1}
          watchSlidesProgress={true}
          centeredSlides={true}
          spaceBetween={70}
        >
          {projects.map((project, index) => {
            if (filterBy ? project.cat === filterBy : true) {
              return (
                <SwiperSlide key={index}>
                  <ProjectItem project={project} />
                </SwiperSlide>
              );
            } else {
              return null;
            }
          })}
        </Swiper>
      </motion.div>
    </ProjectsStyle>
  );
}

export default Projects;
