import { GitHub } from "@mui/icons-material";
import { Grid } from "@mui/material";
import { motion } from "framer-motion";
import React from "react";
import ReactGA from "react-ga";
import styled from "styled-components";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

const ProjectItemStyles = styled.div`
  .project-container {
    border-radius: 12px;
    display: flex;
    height: 80vh;
    background-size: cover !important;
    background-position: center;
    background-repeat: no-repeat !important;
    cursor: grab;
    @media (max-width: 600px) {
      display: block;
      background: none !important;
      height: auto;
      background-color: var(--sec-backg) !important;
    }
  }

  .project-container::after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 12px;
    background: linear-gradient(
      to right,
      var(--background) -50%,
      transparent
    );
    will-change: transform;
    z-index: 0;
    @media (max-width: 600px) {
      background: none !important;
    }
  }
  .project-thumbnail {
    height: 50% !important;
    overflow: hidden;
    @media (min-width: 600px) {
      display: none;
    }
  }

  .project-thumbnail > img {
    width: 100%;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }

  .project-info {
    padding: 20px;
    z-index: 10;
    width: 60%;
    @media (max-width: 800px) {
      width: auto;
    }
  }

  .project-title {
    font-size: clamp(24px, 5vw, 28px);
  }

  .project-description {
    position: relative;
    align-items: center;
    margin-block: 20px;
  }

  .project-techs {
    margin-block: 10px;
  }

  .tech:last-child {
    margin-right: 0;
  }

  .project-links {
    margin-top: 15px;
  }

  .project-links > a {
    margin-inline: 10px;
  }

  .project-links :first-child {
    margin-inline: 0;
    margin-right: 10px;
  }

  .project-links :last-child {
    margin-inline: 0;
  }
`;

export default function ProjectItem({ project }) {
  const container = {
    show: {
      transition: { staggerChildren: 0.3 },
    },
  };

  const item = {
    initial: {
      x: 100,
      opacity: 0,
    },
    show: {
      x: 0,
      opacity: 1,
      transition: {
        ease: [0.6, 0.01, -0.05, 0.95],
        duration: 0.8,
      },
    },
  };
  return (
    <ProjectItemStyles>
      <div
        className="project-container"
        style={{ background: `url(${project.img})` }}
      >
        <div className="project-thumbnail">
          <img src={project.img} alt={project.name} />
        </div>
        <motion.div
          variants={container}
          initial="initial"
          whileInView="show"
          viewport={{ once: true }}
          className="project-info"
        >
          <motion.h1 variants={item} className="project-title">
            {project.name}
          </motion.h1>
          {project.desc ? (
            <motion.div variants={item} className="project-description">
              {project.desc}
            </motion.div>
          ) : (
            <></>
          )}
          <motion.div variants={item}>
            <Grid className="project-techs" columnSpacing={2} container>
              {project.techs?.map((tech, index) => (
                <Grid key={index} item>{tech}</Grid>
              ))}
            </Grid>
          </motion.div>
          {project.link || project.github ? (
            <motion.div variants={item} className="project-links">
              {project.github ? (
                <a href={project.github} target="_blank" rel="noreferrer" onClick={() => ReactGA.event({category: "GithubBtn", action: project.name})}>
                  <GitHub sx={{ color: "var(--text-primary)" }} />
                </a>
              ) : null}
              {project.link ? (
                <a href={project.link} target="_blank" rel="noreferrer" onClick={() => ReactGA.event({category: "LinkBtn", action: project.name})}>
                  <OpenInNewIcon sx={{ color: "var(--text-primary)" }} />
                </a>
              ) : null}
            </motion.div>
          ) : null}
        </motion.div>
      </div>
    </ProjectItemStyles>
  );
}
