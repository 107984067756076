import { useEffect, useState } from "react";
import ReactGA from "react-ga";
import useLocalStorage from "use-local-storage";
import { motion, useScroll } from "framer-motion";

import {
  Navbar,
  Head,
  About,
  Projects,
  Contact,
  Footer,
  Loader,
} from "./components";

import "./index.css";

const TRACKING_ID = "UA-74833769-5";
ReactGA.initialize(TRACKING_ID);

function App() {
  const [theme, setTheme] = useLocalStorage("theme" ? "dark" : "light");

  const { scrollYProgress } = useScroll();

  const [loading, setLoading] = useState(false);

  const switchTheme = () => {
    const newTheme = theme === "light" ? "dark" : "light";
    ReactGA.event({ category: "SwitchTheme", action: newTheme });
    setTheme(newTheme);
  };

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
    console.log("👋")
  }, []);

  return (
    <div className="app" data-theme={theme}>
      {loading ? (
        <Loader setLoading={setLoading} />
      ) : (
        <>
          <motion.div
            className="progress-bar"
            style={{ scaleX: scrollYProgress }}
          />
          <Navbar switchTheme={switchTheme} theme={theme} />
          <Head theme={theme} />
          <About theme={theme} />
          <blockquote className="quote">
            <p>
              Do what you love,
              <br />
              love what you do.
            </p>
            <p
              style={{
                transform: "rotate(54deg)",
                marginTop: "-35px",
                fontSize: "5rem",
              }}
            >
              /
            </p>
          </blockquote>

          <Projects />
          <blockquote className="quote">
            <p>
              We are all on a
              <br/>
              never ending
              <br/>
              learning journey.
            </p>
          </blockquote>
          <Contact />
          <Footer />
        </>
      )}
    </div>
  );
}

export default App;
