import React from "react";
import ReactGA from "react-ga";
import { Button } from "@mui/material";
import { motion } from "framer-motion";

import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";

import "./Head.css";

export default function Head({ theme }) {
  const container = {
    show: { transition: { staggerChildren: 0.3 } },
  };

  const item = {
    initial: {
      x: -400,
      opacity: 0,
    },
    show: {
      x: 0,
      opacity: 1,
      transition: {
        ease: [0.6, 0.01, -0.05, 0.95],
        duration: 0.6,
      },
    },
  };

  const particlesInit = async (main) => {
    await loadFull(main);
  };

  const particalesColor = !(theme === "light") ? "#fff" : "#000";

  return (
    <motion.section
      variants={container}
      initial="initial"
      animate="show"
      className="head-container"
      onClick={() => ReactGA.event({ category: "Head", action: "Click" })}
    >
      <div className="head-description">
        <motion.p variants={item}>I am</motion.p>
        <motion.h1 variants={item} className="name">
          Mohamed Rida El&nbsp;Bakri
        </motion.h1>
        <motion.p variants={item} className="description">
          Passionate about designing and building excellent stuff that improves
          the lives of those around me. Addicted to music and visual arts.
        </motion.p>
        <motion.div variants={item}>
          <Button
            href="#aboutme"
            variant="outlined"
            className="aboutme-btn"
            onClick={() =>
              ReactGA.event({ category: "Head", action: "AboutMe" })
            }
          >
            About Me
          </Button>
        </motion.div>
      </div>
      <Particles
        id="tsparticles"
        init={particlesInit}
        options={{
          fullScreen: {
            enable: false,
          },
          particles: {
            number: {
              value: 20,
              density: {
                enable: true,
                value_area: 800,
              },
            },
            color: {
              value: `${particalesColor}`,
            },
            opacity: {
              value: 0.5,
            },
            size: {
              value: 3,
            },
            line_linked: {
              enable: true,
              distance: 150,
              color: `${particalesColor}`,
              opacity: 0.4,
              width: 1,
            },
            move: {
              enable: true,
              speed: 6,
              bounce: true,
            },
          },
          interactivity: {
            events: {
              onhover: {
                enable: true,
                mode: "grab",
              },
              onclick: {
                enable: true,
                mode: "push",
              },
              resize: true,
            },
            modes: {
              grab: {
                distance: 140,
                line_linked: {
                  opacity: 1,
                },
              },
              bubble: {
                distance: 400,
                size: 40,
                duration: 2,
                opacity: 8,
                speed: 3,
              },
              repulse: {
                distance: 200,
                duration: 0.4,
              },
              push: {
                particles_nb: 4,
              },
              remove: {
                particles_nb: 2,
              },
            },
          },
          retina_detect: true,
        }}
      />
    </motion.section>
  );
}
