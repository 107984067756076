import React from "react";
import ReactGA from "react-ga";
import { Button } from "@mui/material";
import styled from "styled-components";
import DarkPicture from "../../assets/DarkPicture.png";
import LightPicture from "../../assets/LightPicture.png";
import { motion } from "framer-motion";

const StyledAboutMe = styled.section`
  display: flex;
  align-items: center;
  height: 100vh;
  @media (max-width: 600px) {
    flex-direction: column-reverse;
    height: auto;
  }

  p {
  }

  .main-picture {
    width: 30vw;
    margin-right: 40px;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    @media (max-width: 600px) {
      width: 80%;
      margin-right: 0;
    }
  }

  .about-me-text {
    display: block;
  }

  .box {
    position: relative;
    width: 100vw;
    height: 300px;
    background-color: transparent;
    margin: 20px;
  }

  .box::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 300px;
    background: transparent;
    box-shadow: 0 70px 0 60px var(--background);
    z-index: 1;
    border-radius: 50%;
  }

  .box .circle {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 300px;
    background: red;
    overflow: hidden;
    border-radius: 50%;
  }

  .box .circle img,
  .box img {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 450px;
  }
`;

function AboutMe({ theme }) {

  const container = {
    show: {
      transition: { staggerChildren: 0.2 },
    },
  };

  const item = {
    initial: {
      y: 400,
      opacity: 0,
    },
    show: {
      y: 0,
      opacity: 1,
      transition: {
        ease: [0.6, 0.01, -0.05, 0.95],
        duration: 0.8,
      },
    },
  };

  return (
    <StyledAboutMe id="aboutme" onClick={() => ReactGA.event({category: "About", action: "Component"})}>
      <motion.img
        initial={{ opacity: 0, y: 200 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
        viewport={{ once: true }}
        className="main-picture"
        src={!(theme === "light") ? LightPicture : DarkPicture}
        alt=""
      />
      <motion.div
        variants={container}
        initial="initial"
        whileInView="show"
        viewport={{ once: true }}
        className="about-me-text"
      >
        <motion.h1 variants={item}>About me</motion.h1>
        <br />
        <motion.p variants={item}>
          I recently obtained a bachelor's degree in software engineering at the
          Faculty of Science and Technology in Errachidia.
        </motion.p>
        <br />
        <motion.p variants={item}>
          I am currently a master's degree student in Big Data and Internet of Things
          at the National School of Arts and Crafts - ENSAM Casablanca.
        </motion.p>
        <br />
        <motion.p variants={item}>
          In parallel, I do remote freelance work on different projects with
          clients from all over the world.
        </motion.p>
        <motion.div variants={item}>
          <Button variant="contained" onClick={() => ReactGA.event({category: "About", action: "Resume"})}>Resume.pdf</Button>
          <Button href="#work" variant="text" onClick={() => ReactGA.event({category: "About", action: "Something I've built"})}>
            Something I've built
          </Button>
        </motion.div>
      </motion.div>
    </StyledAboutMe>
  );
}

export default AboutMe;
